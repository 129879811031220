import * as React from "react"
import { Sizes, Skin } from "../../../data/models"

import "./Button.scss"

type Props = {
    className?: string
    disabled?: boolean
    isActive?: boolean
    icon?: string
    size?: Sizes
    skin?: Skin
    onClick?(): void
    children?: React.ReactNode
}

function Button(props: Props) {
    const { children, className, skin, size, disabled, isActive, onClick } = props

    let customClassName = "button"

    if (skin)
        customClassName += " button__skin--" + skin

    if (size)
        customClassName += " button__size--" + size

    if (disabled)
        customClassName += " button--disabled"

    if (isActive)
        customClassName += " button--active"

    if (className)
        customClassName += " " + className

    return (
        <button className={customClassName} onClick={onClick}>
            {children}
        </button>
    )
}

export default Button