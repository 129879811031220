import * as React from "react"
import "./Loader.scss"

type Props = {
    className?: string
}

function Loader(props: Props) {
    const { className } = props

    return (
        <div className={"loader " + (className ?? "")} />
    )
}

export default Loader