import { Tire } from "../../../data/models"
import { Table, Text, Button } from "../../_shared"

type Props = {
    loading?: boolean
    tires: Tire[]
    onAddtoBasket(item: Tire): void
}

function TiresTable(props: Props) {

    const { tires, loading, onAddtoBasket } = props

    const renderBrand = (tire: Tire) => <Text>{tire.brand}</Text>

    const renderPrice = (tire: Tire) => <Text strong skin="primary">{tire.price}</Text>

    const renderSize = (tire: Tire) => <Text>{tire.size}</Text>

    const renderSeason = (tire: Tire) => <Text>{tire.season}</Text>

    const renderActions = (tire: Tire) => {
        return (
            <Button
                icon="add"
                onClick={() => onAddtoBasket(tire)}
            >
                Add
            </Button>
        )
    }

    return (
        <Table
            className="tires-table"
            headers={["Size", "Brand", "Season", "Price", "Actions"]}
            loading={loading}
            items={tires}
            columns={[renderSize, renderBrand, renderSeason, renderPrice, renderActions]}
        />
    )
}

export default TiresTable