import * as React from 'react'
import { Table, Text } from "../_shared"
import { Tire } from '../../data/models'
import { useSelector } from 'react-redux'
import { RootState } from '../../business'
import "./Basket.scss"

type Props = {

}

function Basket(props: Props) {
    const basketItems = useSelector<RootState, Array<Tire>>(state => state.basket?.items)

    const renderSize = (tire: Tire) => <Text>{tire.size}</Text>
    const renderBrand = (tire: Tire) => <Text>{tire.brand}</Text>
    const renderSeason = (tire: Tire) => <Text>{tire.season}</Text>
    const renderPrice = (tire: Tire) => <Text strong skin="primary">{tire.price}</Text>
    const renderQuantity = (tire: Tire) => <Text strong skin="primary">1</Text>

    return (
        <div className="basket">
            <Text size="l">Basket</Text>
            <Table
                className="basket-table"
                headers={["Size", "Brand", "Season", "Price", "Quantity"]}
                loading={false}
                items={basketItems}
                columns={[renderSize, renderBrand, renderSeason, renderPrice, renderQuantity]}
            />
        </div>
    )
}

export default Basket