import * as React from 'react'
import { Text, Icon } from "../_shared"
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState } from '../../business'
import "./Header.scss"

function Header() {
    const navigate = useNavigate()
    const basketCount = useSelector<RootState, number>(state => state.basket?.items?.length)

    return (
        <div className="header">
            <div id="logo" onClick={() => navigate("/")} />
            <Text strong size="l"> Tires store - powered by Topmotive</Text>
            <Icon onClick={() => navigate("/basket")} badge={basketCount} name="basket" size="l"></Icon>
        </div>
    )
}

export default Header