import * as React from 'react'
import { Repositories } from '../../data/repositories'
import { Vehicle, Tire } from '../../data/models'
import VehicleTable from './components/VehicleTable'
import { StoreType } from '../../business/model'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { IActions, Actions } from '../../business/actions'
import TiresTable from './components/TiresTable'
import "./Home.scss"

type Props = StoreProps & DispatchProps & {

}

type StoreProps = {
    vehiclesItems: Vehicle[]
    selectedVehicle?: Vehicle
    vehiclesItemsLoading: boolean
    tires: Tire[]
    tiresLoading: boolean
}

type DispatchProps = {
    actions: IActions
}

function Home(props: Props) {

    const { actions, tires, tiresLoading, vehiclesItems, vehiclesItemsLoading, selectedVehicle } = props

    // load vehicles
    React.useEffect(() => {
        if (!actions) {
            return
        }

        actions.vehiclesLoading()

        Repositories.getAllVehicles().then(
            vehicles => actions.vehiclesLoaded(vehicles)
        )
    }, [actions])

    const handleVehicleSelect = React.useCallback((vehicle: Vehicle) => {
        actions.selectVehicle(vehicle)
        Repositories.getTiresById(vehicle.id).then(tires => {
            actions.tiresLoaded(tires)
        })
    }, [actions])

    const handleAddTireToBasket = React.useCallback((tire: Tire) => {
        actions.addTireToBasket(tire)
    }, [actions])

    return (
        <div className="home">
            <VehicleTable
                onSelectVehicle={handleVehicleSelect}
                vehicles={vehiclesItems}
                loading={vehiclesItemsLoading}
                selectedVehicle={selectedVehicle}
            />

            {selectedVehicle &&
                <TiresTable
                    tires={tires}
                    loading={tiresLoading}
                    onAddtoBasket={handleAddTireToBasket}
                />}
        </div>
    )
}


function mapStateToProps(store: StoreType): StoreProps {
    return {
        vehiclesItems: store.vehicles.items,
        vehiclesItemsLoading: store.vehicles.loading ?? false,
        selectedVehicle: store.selectedVehicle,
        tires: store.tires.items,
        tiresLoading: store.tires.loading ?? false
    }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
    return {
        actions: {
            vehiclesLoading: () => dispatch(Actions.vehiclesLoading()),
            // vehiclesLoaded: (vehicle: Vehicle[]) => diapatch(Actions.vehiclesLoaded(vehicle))
            vehiclesLoaded: bindActionCreators(Actions.vehiclesLoaded, dispatch),
            selectVehicle: bindActionCreators(Actions.selectVehicle, dispatch),
            tiresLoaded: bindActionCreators(Actions.tiresLoaded, dispatch),
            addTireToBasket: bindActionCreators(Actions.addTireToBasket, dispatch)

        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)