import * as React from "react"
import { Sizes } from "../../../data/models"
import Text from "../Text/Text"
import "./Icon.scss"

type Props = {
    className?: string
    name: string
    size?: Sizes
    badge?: number
    onClick?: () => void
    children?: React.ReactNode
}

function Icon(props: Props) {
    const { className, size, name, onClick, badge } = props

    let customClassName = "icon"

    if (size)
        customClassName += " icon__size--" + size


    if (className)
        customClassName += " " + className

    if (onClick)
        customClassName += " icon--clickable"

    return (
        <span className={customClassName} onClick={onClick}>
            <img src={"/images/icons/" + name + ".svg"} alt={name} />
            {!!badge && <div className="badge">
                <Text size="s">{badge}</Text>
            </div>}
        </span>
    )
}

export default Icon