import * as React from "react"
import { Vehicle } from "../../../data/models"
import { Table, Text, Button } from "../../_shared"

type Props = {
    loading?: boolean
    vehicles: Vehicle[]
    selectedVehicle?: Vehicle
    onSelectVehicle: (vehicle: Vehicle) => void
}

function VehicleTable(props: Props) {

    const { vehicles, loading, selectedVehicle, onSelectVehicle } = props

    const renderManufacturer = (vehicle: Vehicle) => <Text>{vehicle.manufacturerName}</Text>

    const renderLogo = (vehicle: Vehicle) => {
        return <img src={vehicle.manufacturerLogo} style={{ width: '3em', height: '2em' }} alt={vehicle.name} />
    }

    const renderName = (vehicle: Vehicle) => <Text>{vehicle.name}</Text>

    const renderActions = (vehicle: Vehicle) => {
        const isActive = selectedVehicle?.id === vehicle.id
        return (
            <Button
                icon="arrow-right"
                skin={isActive ? "highlight" : undefined}
                onClick={() => onSelectVehicle(vehicle)}
            >
                Select
            </Button>
        )
    }

    return (
        <Table
            className="vehicles-table"
            headers={["Logo", "Manufacturer", "Model", "Actions"]}
            loading={loading}
            items={vehicles}
            columns={[renderLogo, renderManufacturer, renderName, renderActions]}
        />
    )
}

export default VehicleTable