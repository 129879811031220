import { Route, Routes } from "react-router"
import { Provider } from "react-redux"
import { store } from "../../business"
import { Header } from "../Header"
import { Home } from "../home"
import { Basket } from "../Basket"
import { BrowserRouter } from 'react-router-dom'
import { NoRoute } from "../_shared"
import "./App.scss"

const App = () =>
    <Provider store={store}>
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/basket" element={<Basket />} />
                <Route path="*" element={<NoRoute />} />
            </Routes>
        </BrowserRouter>
    </Provider>

export default App
